<template>
  <div class="wrap">
    <!-- <van-nav-bar title="查看医嘱" left-arrow @click-left="onClickLeft" /> -->
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold">
        查看医嘱
      </span>
    </div>
    <van-cell-group>
      <van-field
        v-model="cardNo"
        label="就诊卡号"
        placeholder="请输入患者就诊卡号"
      />
      <van-field
        v-model="visitId"
        label="就诊号"
        placeholder="请输入患者就诊号"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        label="医嘱类型"
        placeholder="请选择医嘱类型"
        @click="showPicker = true"
      />
    </van-cell-group>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <div class="btn" @click="goInfo">查询</div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 1,
      value: "",
      visitId: "",
      cardNo: "",
      repeatIndicator: "",
      backIcon: localStorage.getItem("routerHistory"),
      columns: [
        { text: "长期医嘱", value: "1" },
        { text: "临时医嘱", value: "0" },
      ],
      showPicker: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/menu" });
    },
    goInfo() {
      this.cardNo = this.cardNo.trim();
      this.visitId = this.visitId.trim();
      if (!this.cardNo) {
        Toast.success({ type: "error", message: "就诊卡号不能为空" });
        return;
      }
      if (!this.visitId) {
        Toast.success({ type: "error", message: "就诊号不能为空" });
        return;
      }
      if (!this.value) {
        Toast.success({ type: "error", message: "医嘱类型不能为空" });
        return;
      }
      this.$router.push({
        path: "/doctorAdviceList",
        query: {
          cardNo: this.cardNo,
          visitId: this.visitId,
          repeatIndicator: this.repeatIndicator,
        },
      });
    },
    onConfirm(value) {
      this.value = value.text;
      this.repeatIndicator = value.value;
      this.showPicker = false;
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 20px;
  .btn {
    width: 90%;
    height: 40px;
    margin: 0 auto;
    background: #1578f9;
    font-size: 18px;
    border-radius: 23px 23px 23px 23px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
  }
  .nav_bar {
    text-align: center;
    color: #323233;
  }
}

/deep/.van-cell-group {
  margin: 16px;
  border-radius: 10px;
}

/deep/.van-cell {
  border-radius: 10px;
}
</style>
